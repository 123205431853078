import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contact"} />
		<Helmet>
			<title>
				Фітнес-центр Apex
			</title>
			<meta name={"description"} content={"Підвищуй свою силу"} />
			<meta property={"og:title"} content={"Фітнес-центр Apex"} />
			<meta property={"og:description"} content={"Підвищуй свою силу"} />
			<meta property={"og:image"} content={"https://zenivolve.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://zenivolve.com/img/4363463.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://zenivolve.com/img/4363463.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://zenivolve.com/img/4363463.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://zenivolve.com/img/4363463.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://zenivolve.com/img/4363463.png"} />
			<meta name={"msapplication-TileImage"} content={"https://zenivolve.com/img/4363463.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 90px 0" quarkly-title="Contacts-3">
			<Text
				lg-padding="0px 0px 0px 0px"
				sm-font="normal 700 36px/1.2 --fontFamily-sans"
				margin="0px 0px 18px 0px"
				font="normal 600 42px/1.2 --fontFamily-sans"
				color="--darkL1"
				text-align="left"
				padding="0px 0 0px 0px"
				lg-width="100%"
				lg-margin="0px 0px 25px 0px"
			>
				Контакти
			</Text>
			<Text
				margin="16px 40% 50px 0px"
				font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
				lg-margin="0px 0 50px 0px"
				text-align="left"
				sm-margin="0px 0 35px 0px"
				color="#60666d"
				lg-max-width="720px"
			>
				Ми будемо раді почути вас! Якщо у вас є питання, вам потрібна допомога або ви хочете запланувати екскурсію нашими об'єктами, не соромтеся звертатися до нас:
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="36px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 0px 0px 18px"
					border-width="0 0 0 2px"
					border-style="solid"
					border-color="--color-lightD2"
				>
					<Text margin="0px 0px 15px 0px" font="normal 600 22px/1.2 --fontFamily-sans">
						Контакти
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#60666d">
						support@zenivolve.com
						<br /><br />
						+38097 745 65 15
						<br /><br />
						вул. Балтська 133, Петровірівка
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 0px 0px 18px"
					border-width="0 0 0 2px"
					border-style="solid"
					border-color="--color-lightD2"
				>
					<Text margin="0px 0px 15px 0px" font="normal 600 22px/1.2 --fontFamily-sans">
						Партнерство
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#60666d">
						info@zenivolve.com
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 0px 0px 18px"
					border-width="0 0 0 2px"
					border-style="solid"
					border-color="--color-lightD2"
				>
					<Text margin="0px 0px 15px 0px" font="normal 600 22px/1.2 --fontFamily-sans">
						Карʼєра
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#60666d">
						manager@zenivolve.com
						<br /><br />
						+38(099)211-73-65
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65ccedc78e2e8e0021782120"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});